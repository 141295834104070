import React from 'react'
import Layout from '../../templates/layout'
import Seo from '../../helpers/seo'
import Slogan from '../../components/molecules/Slogan'
import { StaticImage } from 'gatsby-plugin-image'
import Button from '../../components/molecules/Button'
import SectionContainer from '../../components/molecules/SectionContainer'
import { isMobile } from 'react-device-detect'
import './styles.scss'

const Banner = () => {
  return (
    <section className="banner-404">
      {isMobile ? (
        <StaticImage
          alt="DR_"
          layout="fixed"
          loading="eager"
          objectFit="cover"
          objectPosition="6% center"
          placeholder="blurred"
          src="../../assets/images/banner-404.webp"
          width={920}
        />
      ) : (
        <StaticImage
          alt="DR_"
          layout="fixed"
          loading="eager"
          objectFit="cover"
          objectPosition="6% center"
          placeholder="blurred"
          src="../../assets/images/banner-404.webp"
          width={1920}
        />
      )}
    </section>
  )
}

const Ops = () => {
  return (
    <SectionContainer>
      <article className="ops">
        <p className="ops__paragraph">Foi mal, não encontramos o que você procura :(</p>

        <Button
          text="Voltar ao início"
          classButton="primary"
          fontColor="orange-dark"
          link="/"
        />
      </article>
    </SectionContainer>
  )
}

const CTA = () => {
  return (
    <section className="CTA-404">
      <h2 className="CTA-404__title">Precisa de ajuda?</h2>
      <Button
        text="Fale com a gente"
        classButton="primary"
        fontColor="orange-dark"
        link="/contato"
      />
    </section>
  )
}

const Error404Page = () => {
  return (
    <Layout page="Erro 404">
      <Banner />
      <Slogan>
        <b>Opss...</b>
      </Slogan>
      <Ops />
      <CTA />
    </Layout>
  )
}

export const Head = () => <Seo title="404" />

export default Error404Page
